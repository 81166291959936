<template>
  <div class="box">
    <div class="set-calc" v-if="!isMobile">
      <div class="set-calc__col">
        <label for="grant">גובה מענק בשקלים:</label>
        <Slider
          v-if="showSlider"
          v-model="grant"
          direction="rtl"
          :lazy="false"
          :min="minRange"
          :max="maxRange"
          :step="stepRange"
          :format="
            (val) =>
              formatSliderValue(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          @update="(value) => setLeasingStep(value)"
        />
      </div>
      <div v-if="!isMobile" class="set-calc__col">
        <p class="">תחילת הכנסה לאחר:</p>
        <div class="set-calc__btns">
          <button
            v-for="(num, index) in delays"
            :key="num"
            :class="{ active: index == selectedProgram?.Delay }"
            @click="() => setDelay(index)"
          >
            {{ num }}
          </button>
        </div>
      </div>
    </div>
    <div class="box__data">
      <data-card
        v-for="card in cards"
        :key="card.title"
        :title="card.title"
        :number="card.number"
        :afterNumber="card.afterNumber"
        :beforeNumber="card.beforeNumber"
        :tooltip="card.tooltip"
        :icon="card.icon"
      />
    </div>
    <div class="box__bottom">
      <div class="box__btns">
        <a
          v-if="!isMobile"
          :href="selectedProgram?.URL"
          class="btn btn--secondary"
          target="_blank"
          @click="() => dataLayerAgreement()"
          >להסכם</a
        >
        <button class="btn btn--group" disabled v-if="!isMobile">
          להטבת קבוצה
        </button>
      </div>
    </div>
    <button
      class="btn btn--empty open-calc"
      v-if="isMobile"
      @click="openMobileCalc"
    >
      מחשבון ליסינג
    </button>
    <button class="btn btn--group" disabled v-if="isMobile">להטבת קבוצה</button>
  </div>
</template>
<script>
// core
import { useStore } from "vuex";
import { nextTick, ref, toRef, computed } from "vue";
import { createDataLayerEvent } from "@/inc/helpers";

// 3rd
import Slider from "@vueform/slider";

// Components
import DataCard from "@/components/DataCard.vue";

export default {
  components: { DataCard, Slider },
  setup() {
    const store = useStore();
    const { commit } = store;
    const proposal = toRef(store.getters.userData, "ProposalOutput");
    const isMobile = toRef(store.getters, "isMobile");
    const leasindData = store.getters.userData.LeasingSteps;
    const content = store.getters.content;

    const selectedProgram = toRef(store.state, "currentLeasing");

    const stepRange = 1;
    const minRange = 0;
    const maxRange = 5;
    const grant = selectedProgram.value ? selectedProgram.value.Step : 3;
    const showSlider = ref(true);

    commit(
      "setCurrentLeasing",
      selectedProgram.value
        ? {
            step: selectedProgram.value.Step,
            delay: selectedProgram.value?.Delay,
          }
        : { step: 3, delay: 0 }
    );

    const formatSliderValue = (val) =>
      "₪" +
      leasindData.find(
        (el) => el.Step == val && el.Delay == selectedProgram.value?.Delay
      )?.PrePayment;

    const reRenderSlider = () => {
      showSlider.value = false;
      nextTick(() => {
        showSlider.value = true;
      });
    };

    const openMobileCalc = () => store.commit("setModal", "mobileCalc");
    const setDelay = (index) => {
      commit("setCurrentLeasing", {
        step: selectedProgram.value.Step,
        delay: index,
      });
      reRenderSlider();

      createDataLayerEvent({
        event: "price_quote",
        pricing_model: "Leasing",
        income_start_date: index + " Years",
        grant_amount: Math.round(selectedProgram.value?.TotalPayments),
        agreement_id: selectedProgram?.value?.URL,
      });
    };
    const setLeasingStep = (value) => {
      commit("setCurrentLeasing", {
        step: value,
        delay: selectedProgram.value.Delay,
      });
      createDataLayerEvent({
        event: "Price Quote - Slider",
        slider_amount: selectedProgram.value.PrePayment,
      });
    };

    const delays = ["מיידי", "שנה", "שנתיים", "שלוש שנים"];

    const cards = computed(() => [
      {
        title: "עלות מערכת",
        number: 0,
        afterNumber: "₪",
        beforeNumber: null,
      },
      {
        title: "תחילת הכנסה לאחר",
        number: delays[selectedProgram.value?.Delay],
        afterNumber: null,
        beforeNumber: null,
      },
      {
        title: "הכנסה שנתית קבועה",
        number: Math.round(selectedProgram.value?.Yearly),
        afterNumber: "₪",
        beforeNumber: null,
      },
      {
        title: "סה״כ הכנסות",
        number: Math.round(selectedProgram.value?.TotalPayments),
        afterNumber: "₪",
        beforeNumber: null,
        tooltip: content.price_offer.leasing_tooltip,
        icon: require("@/assets/icon_coin.svg"),
      },
      {
        title: "מענק יציאה לדרך",
        number: Math.round(selectedProgram.value?.PrePayment),
        beforeNumber: null,
        icon: require("@/assets/icon_money_04.svg"),
        afterNumber: "₪",
      },
    ]);

    const dataLayerAgreement = () => {
      createDataLayerEvent({
        event: "price_quote",
        pricing_model: "Leasing",
        buttons: "Agreement",
        grant_amount: Math.round(selectedProgram.value?.TotalPayments),
        agreement_id: selectedProgram?.value?.URL,
      });
    };

    return {
      cards,
      proposal,
      isMobile,
      openMobileCalc,
      grant,
      setDelay,
      setLeasingStep,
      selectedProgram,
      stepRange,
      minRange,
      maxRange,
      formatSliderValue,
      showSlider,
      delays,
      dataLayerAgreement,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@vueform/slider/themes/default.css";
@import "@/scss/setup";

.text {
  margin: vw(16) 0 vw(22);
  font-weight: 300;
}

.set-calc {
  font-size: vw(20, 1920);

  @include break($large) {
    font-size: vw(16);
  }

  label {
    display: block;
    margin-bottom: 7vh;
  }

  p {
    display: block;
    margin: 0 0 vw(10);
  }

  .grant {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    height: vw(8);
    border-radius: vw(10);
    background-color: #c4c4c4;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: vw(32);
      height: vw(32);
      background: $primary;
      cursor: pointer;
      border-radius: 50%;
    }
  }

  .grant-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: vw(9);
    font-size: vw(12);
  }

  &__col {
    flex: 0 0 50%;
    padding: 1vh vw(10);
  }

  &__btns {
    display: flex;
    justify-content: space-between;
  }

  button {
    color: #00358a80;
    flex: 0 0 23.5%;

    display: block;
    border-radius: 2px;
    font-weight: bold;
    border-radius: 1px;
    box-shadow: 0 0 4px 0 rgba(0, 53, 138, 0.2);
    background-color: #e9eef7;
    box-sizing: border-box;

    font-size: vw(28, 1920);
    height: vw(36);

    @include break($large) {
      font-size: vw(20);
    }

    &:hover,
    &:focus {
      color: $primary;
      border-radius: 2px;
      box-shadow: 0 0 8px 2px rgba(0, 24, 64, 0.3);
      border: solid 1px #00358a;
      background-color: #e9eef7;
    }

    &.active {
      background-color: $primary;
      color: #fff;
      border-radius: 2px;
      border: solid 1px #00358a;
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .btn {
    &--group {
      background: #545454;
      color: #ffffff;
      float: left;
      opacity: 1;
      &:hover,
      &:focus,
      &:active {
        opacity: 0.75;
      }

      @include break($small) {
        width: 100%;
        margin-top: 0 !important;
      }
    }
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    margin: 0 vw(-4) vw(10);
    text-align: center;

    @include break($small) {
      margin-top: 0;
      justify-content: space-between;
    }

    > .card {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        flex: 1 0 calc(30% - vw(10));

        @include break($small) {
          flex: 1 0 100%;
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        flex-grow: 1;

        @include break($small) {
          flex: 1 0 100%;
          z-index: 1;
        }
      }

      @include break($small) {
        &:nth-child(1) {
          // z-index: 1;
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          //order: -1;
        }
      }
    }
  }

  .open-calc {
    @include break($small) {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 8px;
      background-color: #f5f8fd;
    }
  }
}
</style>
<style>
.slider-txt-dir-rtl.slider-horizontal .slider-handle {
  left: auto;
}
</style>
